import { Injectable } from "@angular/core";
import { Model } from "../admin.interfaces";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { loadUserHistoryStarted, loadUserHistoryCompleted } from "../state/actions";

@Injectable({ providedIn: 'root' })
export class CommandGetUserHistory {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
    ) {}

    public async retrieve(
        userId: number,
        pageSize: number,
        pageNumber: number,
    ): Promise<void> {
        this.store.dispatch(loadUserHistoryStarted());
        const userHistory: Model.UserHistoryResponse = await this.requestUserDetails(userId, pageSize, pageNumber);

        this.store.dispatch(loadUserHistoryCompleted({
            total: userHistory.total,
            history: userHistory.history.map(historyItem => {
                const mappedHistoryItem: Model.UserByIdHistoryItem = {
                    feature: historyItem.feature,
                    environment: historyItem.environment,
                    data: historyItem.data,
                    created: new Date(historyItem.created),
                }

                return mappedHistoryItem;
            })
         }));
    }

    private async requestUserDetails(
        userId: number,
        pageSize: number,
        pageNumber: number,
    ): Promise<Model.UserHistoryResponse> {
        return await this.http
            .get<Model.UserHistoryResponse>(environment.apiUrl + "/api/admin/user-history/", { params: { userId, pageSize, pageNumber } })
            .toPromise();
    }
}