import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Selector } from '../state/selector';
import { Observable } from 'rxjs';
import { Model } from '../admin.interfaces';
import { CommandSearchUsers } from '../commands/command-search-users';
import { CommandProxyInAsUser } from '../commands/command-proxy-in-as';
import { Router } from '@angular/router';
import { EventInputDelay } from 'src/app/miscellaneous/event-input-delay';
import {
  adminUserSearchLoaded,
  persistPortalUsersSearch,
} from '../state/actions';
import { loadUser } from 'src/app/user';
import {
  adminRoute,
  createUserRoute,
  portalUserRoute,
  portalUsersRoute,
} from '../constants';
import { CommandGetUserById } from '../commands/command-get-user-by-id';

@Component({
  selector: 'app-admin-portal-users',
  templateUrl: './admin-portal-users.component.html',
  styleUrls: ['../admin.scss', './admin-portal-users.component.scss'],
})
export class AdminPortalUsersComponent implements OnInit, OnDestroy {
  public users: Observable<Model.UserSearchResult[]>;
  public history: Observable<Model.UserByIdHistoryItem[]>;
  public searchTerm: string = '';

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly selector: Selector,
    private readonly eventInputDelay: EventInputDelay,
    private readonly commandSearchUsers: CommandSearchUsers,
    private readonly commandProxyInAsUser: CommandProxyInAsUser,
    private readonly commandGetUserById: CommandGetUserById
  ) {
    this.users = this.store.select(this.selector.selectUserSearchResult);
    this.history = this.store.select(this.selector.selectEditPortalUsersHistory);
  }

  public ngOnInit(): void {
    const subscription = this.store
      .select(this.selector.selectEditPortalUsersSearch)
      .subscribe((searchTerm: string) => this.search(searchTerm));

    this.store.dispatch(adminUserSearchLoaded());
    subscription.unsubscribe();
  }

  public ngOnDestroy(): void {
    if (this.searchTerm !== null) {
      this.store.dispatch(persistPortalUsersSearch({ searchTerm: null }));
    }
  }

  public async searchForUsers(searchTerm: string): Promise<void> {
    this.eventInputDelay.delay(() => this.search(searchTerm));
  }

  public async editUser(userId: number): Promise<void> {
    this.store.dispatch(
      persistPortalUsersSearch({ searchTerm: this.searchTerm })
    );
    this.searchTerm = null;
    await this.commandGetUserById.retrieve(userId, 10, 0);
    this.router.navigate([adminRoute, portalUserRoute], {
      queryParams: { userId },
    });
  }

  public async proxyInAsUser(userId: number): Promise<void> {
    await this.commandProxyInAsUser.execute(userId);
    this.store.dispatch(loadUser());
    this.router.navigate(['']);
  }

  private search(searchTerm: string): void {
    if (searchTerm && searchTerm.length > 2) {
      this.searchTerm = searchTerm;
      this.commandSearchUsers.execute(searchTerm);
    } else {
      this.store.dispatch(adminUserSearchLoaded());
    }
  }

  onAddUser(): void {
    this.router.navigate([adminRoute, portalUsersRoute, createUserRoute]);
  }
}
