<div class="main-element">
    <app-edit-user [title]="'Manage Portal User'"
                   [displayProducerData]="false">
    </app-edit-user>

    <div class="button-panel">
        <button *ngIf="!editUserConfirmed"
                class="mat-raised-button delete-button"
                (click)="delete()"
                data-cy="portalUsersDeleteBtn">Delete</button>
        <div *ngIf="editUserConfirmed"></div>
        <div>
            <button class="mat-raised-button wide-button"
                    (click)="recentActivity()"
                    data-cy="recentActivityBtn">Recent Activity</button>
            <button class="mat-raised-button decline-button"
                    (click)="cancel()"
                    data-cy="portalUsersCancelBtn">Cancel</button>
            <button *ngIf="!editUserConfirmed"
                    class="mat-raised-button mat-primary"
                    [disabled]="!validToConfirm"
                    (click)="confirm()"
                    data-cy="portalUsersConfirmBtn">Confirm</button>
            <button *ngIf="editUserConfirmed"
                    class="mat-raised-button mat-primary"
                    (click)="approve()"
                    data-cy="portalUsersApproveBtn">Approve</button>
        </div>
    </div>
</div>