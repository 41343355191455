import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  adminRoute,
  changeRequestRoute,
  createUserRoute,
  newRequestRoute,
  newRequestsRoute,
  portalUserRoute,
  portalUsersRoute,
} from './constants';
import { MsalGuard } from '@azure/msal-angular';
import { NewUserRequestsComponent } from './new-user-requests/new-user-requests.component';
import { NewUserRequestComponent } from './new-user-requests/new-user-request.component';
import { AdminPortalUsersComponent } from './portal-users/admin-portal-users.component';
import { AdminPortalUserComponent } from './portal-users/admin-portal-user.component';
import { AdminComponent } from './admin.component';
import { AppContentComponent } from '../app-content.component';
import { CreateUserComponent } from './create-user/create-user.component';

// TODO FEATURE BASED ROUTING & LAZY MODULE FOR ADMIN??
const routes: Routes = [
  {
    path: adminRoute,
    component: AppContentComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        component: AdminComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: newRequestsRoute,
          },
          {
            path: newRequestsRoute,
            component: NewUserRequestsComponent,
          },
          {
            path: newRequestRoute,
            component: NewUserRequestComponent,
          },
          {
            path: portalUsersRoute,
            children: [
              {
                path: '',
                component: AdminPortalUsersComponent,
              },
              {
                path: createUserRoute,
                component: CreateUserComponent,
              },
            ],
          },
          {
            path: portalUserRoute,
            component: AdminPortalUserComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [],
})
export class AdminRoutingModule {}
