import { createAction, props } from '@ngrx/store';
import { DeclineReason } from 'src/app/miscellaneous/enums/decline-reason.enum';
import { Role } from "src/app/miscellaneous/enums/role.enum";
import { Model } from '../admin.interfaces';

export const loadAdminTasksStarted = createAction("[Admin Component] LoadAdminTasksStarted-");
export const loadAdminTasksCompleted = createAction(
    "[Admin Component] LoadAdminTasksCompleted-",
    props<{ adminTasks: Model.AdminTask[] }>(),
);
export const loadUserByIdStarted = createAction("[Admin Component] LoadUserByIdStarted");
export const loadUserByIdCompleted = createAction(
    "[Admin Component] LoadUserByIdCompleted",
    props<{ user: Model.User }>(),
);
export const userEditedStarted = createAction("[Admin Component] UserEditedStarted");
export const userEditedCompleted = createAction("[Admin Component] UserEditedCompleted");
export const searchPropertiesStarted = createAction("[Admin Component] SearchPropertiesStarted-");
export const searchPropertiesCompleted = createAction(
    "[Admin Component] SearchPropertiesCompleted-",
    props<{
        producerCodeKnown: boolean,
        properties: Model.PropertyRole[],
    }>(),
);
export const propertyLockChanged = createAction(
    "[Admin Component] PropertyLockChanged",
    props<{ propertyId: number }>(),
);
export const userEdited = createAction(
    "[Admin Component] UserEdited",
    props<{
        user: Model.User,
        disabled: boolean,
        adminTaskId: number,
    }>(),
);
export const editUserUpdated = createAction(
    "[Admin Component] EditedAdminTaskUpdated",
    props<{ change: Partial<Model.User> }>(),
);
export const editUserPropertySearchUpdated = createAction(
    "[Admin Component] EditUserPropertySearchUpdated",
    props<{ change: string }>(),
);
export const editUserProducerCodeSearchUpdated = createAction(
    "[Admin Component] EditUserProducerCodeSearchUpdated",
    props<{ change: string }>(),
);
export const editUserPropertyRoleUpdated = createAction(
    "[Admin Component] EditUserPropertyRoleUpdated",
    props<{
        propertyId: number,
        role: Role,
    }>(),
);
export const adminTaskApprovedStarted = createAction("[Admin Component] AdminTaskApprovedStarted");
export const adminTaskApprovedCompleted = createAction(
    "[Admin Component] AdminTaskApprovedCompleted",
    props<{ adminTaskId: number }>(),
);
export const adminTaskDeclineStarted = createAction("[Admin Component] AdminTaskDeclineStarted");
export const adminTaskDeclineCompleted = createAction(
    "[Admin Component] AdminTaskDeclineCompleted",
    props<{ adminTaskId: number }>(),
);
export const editUserFixRoles = createAction("[Admin Component] EditUserFixRoles");
export const adminTaskAssignedStarted = createAction("[Admin Component] AdminTaskAssignedStarted");
export const adminTaskAssignedCompleted = createAction(
    "[Admin Component] AdminTaskAssignedCompleted",
    props<{
        success: boolean,
        adminTaskId: number,
        userName: string,
     }>(),
);
export const chooseDeclineReason = createAction(
    "[Admin Component] ApplyDeclineReason",
    props<{ declineReason: DeclineReason }>(),
);
export const changeDeclineReasonText = createAction(
    "[Admin Component] ChangeDeclineReasonText",
    props<{ declineText: string }>(),
);
export const proxyInAsStarted = createAction("[Header Component] ProxyInAsStarted");
export const proxyInAsCompleted = createAction("[Header Component] ProxyInAsCompleted");
export const becomeSelfStarted = createAction("[Header Component] BecomeSelfStarted");
export const becomeSelfCompleted = createAction("[Header Component] BecomeSelfCompleted");
export const adminUserSearchLoaded = createAction("[Admin Component] AdminUserSearchLoaded");
export const adminSearchUsersStarted = createAction("[Admin Component] AdminSearchUsersStarted-");
export const adminSearchUsersCompleted = createAction(
    "[Admin Component] AdminSearchUsersCompleted-",
    props<{ userSearchResults: Model.UserSearchResult[] }>(),
);
export const persistPortalUsersSearch = createAction(
    "[Admin Component] PersistPortalUsersSearch",
    props<{ searchTerm: string }>(),
);
export const deleteUserStarted = createAction("[Admin Component] DeleteUserStarted");
export const deleteUserCompleted = createAction("[Admin Component] DeleteUserCompleted");

export const loadUserHistoryStarted = createAction("[Admin Component] LoadUserHistoryStarted");
export const loadUserHistoryCompleted = createAction(
    "[Admin Component] LoadUserHistoryCompleted",
    props<{
        history: Model.UserByIdHistoryItem[],
        total: number,
    }>(),
);
