import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { Selector } from "../state/selector";
import { MatDialogRef } from "@angular/material/dialog";
import { Model } from "../admin.interfaces";
import { PageEvent } from "@angular/material/paginator";
import { CommandGetUserHistory } from "../commands/command-get-user-history";

@Component({
    selector: "app-recent-user-activity-dialog",
    templateUrl: "./recent-user-activity-dialog.component.html",
    styleUrls: ["./recent-user-activity-dialog.component.scss"],
})
export class RecentUserActivityDialogComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[];
    public history: Model.UserByIdHistoryItem[] = [];
    public pageNumber: number = 0;
    public totalHistoryitems: number = 0;
    private userId: number = 0;

    constructor(
        private readonly dialogRef: MatDialogRef<RecentUserActivityDialogComponent>,
        private readonly store: Store,
        private readonly selector: Selector,
        private readonly commandGetUserHistory: CommandGetUserHistory,
    ) { }
    
    public ngOnInit(): void {
        const tempSubsciption = this.store
            .select(this.selector.selectEditUser)
            .subscribe(async user => {
                this.userId = user.userId;
                await this.commandGetUserHistory.retrieve(user.userId, 10, this.pageNumber);
                tempSubsciption.unsubscribe();
            });
        this.subscriptions = [
            this.store
                .select(this.selector.selectEditPortalUsersHistory)
                .subscribe((history: Model.UserByIdHistoryItem[]) => this.history = history),
            this.store
                .select(this.selector.selectEditPortalUsersHistoryTotal)
                .subscribe((total: number) => this.totalHistoryitems = total),
        ];
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public async onPage(pageEvent: PageEvent): Promise<void> {
        this.pageNumber = pageEvent.pageIndex;
        await this.commandGetUserHistory.retrieve(this.userId, 10, this.pageNumber);
    }
}